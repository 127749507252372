import {
	AutomatitCarousel
} from './automatit_carousel.m';

import {
	getMapsHelper
} from './util/mapsHelper';

export const facilityInfoToggle = (function(){

	let my = {};

	function _facilityInfoToggle() {
		const facilityToggle = document.getElementById('facility_info_toggle');
		const facilityTop = document.getElementById('facility_top');

		facilityToggle.addEventListener('click', function(e){
			let clickedToggle = e.currentTarget;
			clickedToggle.classList.toggle('open');
			facilityTop.classList.toggle('open');
		});
		
	}

	my.init = function(){
		_facilityInfoToggle();
	};

	return my;
})();

export const facilityCaro = (function () {
	let my = {};

	my.init = function () {
		if (document.getElementById('facility_carousel')) {

			if (window.slide_images.length > 1) {
				const options = {
					element: document.getElementById('facility_carousel'),
					speed: 600,
					images: window.slide_images,
					intervalTiming: 6000,
					imagesAsBackgrounds: true,
					showDots: true,
					useCheverons: false
				};
				AutomatitCarousel(options);
			} else {
				let btns = document.getElementById('facility_carousel_chevrons');
				btns.parentNode.removeChild(btns);
				const options = {
					element: document.getElementById('facility_carousel'),
					speed: 600,
					images: window.slide_images,
					imagesAsBackgrounds: true,
					intervalTiming: 0,
					showDots: false
				};
				AutomatitCarousel(options);
			}
		}
	};

	return my;
})();

/* eslint-disable no-undef */
export const locationMap = (function(){
	let my = {},
		map = document.getElementById('facility_map');

	my.init = function(){
		if(document.getElementById('facility_map')){
			const mapHelper = getMapsHelper();
			mapHelper.ready()
				.then(() => {
					const theMap = mapHelper.createMap({
						element: map,
						locationElementSelector: '#facility_map',
						useRichmarker: true,
						markerReducer: el => {
							return {
								lat: el.getAttribute('lat'),
								lng: el.getAttribute('lng'),
								content: `
                                <a class="map_pin" href="${el.getAttribute('link')}" target="_blank">
                                    <img src="/dist/images/icons/location_icon_red.svg" alt="Visit facility" />
                                </a>
                                `
							};
						}
					});

					google.maps.event.addListenerOnce(theMap, 'idle', () => {
						theMap.setZoom(15);
					});
				});
		}
	};

	return my;
})();

export const mapCaroToggle = (function(){

	let my = {};

	function _mapCaroToggle() {
		const toggleButtons = document.querySelectorAll('#facility_caro_toggles .toggle');
		toggleButtons.forEach(toggleButton => {

			toggleButton.addEventListener('click', function(e){

				let clickedItem = e.currentTarget;

				if(clickedItem.getAttribute('data-toggle') === "map"){
					document.getElementById('map_toggle').removeAttribute('active');
					document.getElementById('gallery_toggle').toggleAttribute('active');
					document.getElementById('facility_map').toggleAttribute('active');
				}
				if(clickedItem.getAttribute('data-toggle') === "gallery"){
					document.getElementById('gallery_toggle').removeAttribute('active');
					document.getElementById('map_toggle').toggleAttribute('active');
					document.getElementById('facility_map').removeAttribute('active');
				}
			});
		});
	}

	my.init = function(){
		_mapCaroToggle();
	};

	return my;
})();

export const facilityLinks = (function(){

	let my = {};

	function _facilityLinks() {
		const linksWrapper = document.getElementById('facility_links');
		const links = document.querySelectorAll('#facility_links .facility_link');

		linksWrapper.addEventListener('click', function(e){
			let clickedLink = e.target.closest('a');
			links.forEach(link => {
				link.removeAttribute('active');
			});
			clickedLink.toggleAttribute('active');
		});
		
	}

	my.init = function(){
		_facilityLinks();
	};

	return my;
})();

export const facilityFilter = (function(){
	let my = {};

	function _facilityFilter() {

		const filters = document.querySelectorAll('.facility_filters_buttons .filter');
		const cards = document.querySelectorAll('.unit_card');
		const noUnits = document.querySelector('#no_units');
		document.querySelector('.facility_filters_buttons').addEventListener('click', e => {
			const clickedFilter = e.target.closest('button');
			let unitShown = 0;
			if(clickedFilter) {
				filters.forEach(i => {
					i.removeAttribute('active');
				});
				if(clickedFilter.classList.contains('filter')) {
					clickedFilter.toggleAttribute('active');
				}
				cards.forEach(i => {
					i.setAttribute('hide', true);
					if((i.getAttribute('filter') === clickedFilter.getAttribute('filter') || clickedFilter.getAttribute('filter') === 'all')) {
						unitShown++;
						i.removeAttribute('hide');
					}
				});
				if(unitShown === 0){
					noUnits.style.display = 'block';
				}else {
					noUnits.style.display = 'none';
				}
			}
		});
	}

	my.init = function(){
		_facilityFilter();
	};

	return my;
})();