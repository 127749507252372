import { contactSubmit } from './contact.js';
import { navToggle } from './header.js';
import { facilityMap } from './home.js';
import { facilityInfoToggle, facilityCaro, locationMap, mapCaroToggle, facilityLinks, facilityFilter } from './facility.js';
import { reviews } from './reviews.js';
import { faqsToggle } from './faqs.js';

if (document.getElementById('contact_form')) {
	const file_upload = document.getElementById("file-upload");
	if (file_upload) {
		file_upload.addEventListener("change", () => {
			const file_upload_text = document.getElementById("file_upload_text");

			file_upload_text.value = file_upload.files[0].name;
			console.log(file_upload.files);

			for (let i = 1; i < file_upload.files.length; i++) {
				file_upload_text.value += `, ${file_upload.files[i].name}`;
				console.log(file_upload.files[i].value);
			}
		});
	}
	contactSubmit({
		required: ['name', 'phone', 'email', 'message']
	});
}

navToggle.init();
facilityMap.init();
reviews.init();
facilityCaro.init();
locationMap.init();
mapCaroToggle.init();

if(document.getElementById('facility_info_toggle')){
	facilityInfoToggle.init();
}

if(document.getElementById('facility_links')){
	facilityLinks.init();
}

if(document.getElementById('facility_filters')){
	facilityFilter.init();
}

if(document.getElementById('faqs_list')){
	faqsToggle.init();
}

if(document.getElementById('review_caro')){
	let viewMoreLinks = document.querySelectorAll('#review_caro .view_more');
	let viewLessLinks = document.querySelectorAll('#review_caro .view_less');
	viewMoreLinks.forEach(viewMoreLink => {
		viewMoreLink.addEventListener('click', function(e) {
			let selectedReview = e.target.closest('div');
			selectedReview.classList.add('show_more');
			document.querySelector('#review_caro .caro_inner').style.height = 'auto';
		});
	});
	viewLessLinks.forEach(viewLessLink => {
		viewLessLink.addEventListener('click', function(e) {
			let selectedReview = e.target.closest('div');
			selectedReview.classList.remove('show_more');
			document.querySelector('#review_caro .caro_inner').style.height = 'inherit';
		});
	});
}