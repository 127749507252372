import {
	AutomatitCarousel
} from './automatit_carousel.m';

export const reviews = (function(){
	let my = {};

	my.init = function(){
		if(document.querySelector('#reviews_caro')) {
			window.onload = () => {
				AutomatitCarousel({
					element: document.getElementById('reviews_caro'),
					intervalTiming: 0
				});
				const resizeTimer = setInterval(function(){
					if(document.querySelector('#reviews_caro .caro_inner')){
						let minHeight = 0;
						[].forEach.call(document.querySelectorAll('#reviews_caro .caro_slide'), function(slide){
							let tempDisplay = slide.style.display;
							slide.style.display = 'flex';
							slide.style.marginTop = '-'+(slide.offsetHeight/2)+'px';
							if(slide.offsetHeight > minHeight){
								minHeight = slide.offsetHeight;
							}
							slide.style.display = tempDisplay;
						});
						document.querySelector('#reviews_caro .caro_inner').style.height = minHeight + 'px';
						clearInterval(resizeTimer);
					}
				}, 500);
			};
		}
	};

	return my;
})();