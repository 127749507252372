export const faqsToggle = (function(){

	let my = {};

	function _faqToggle() {
		const faqItems = document.querySelectorAll('#faqs_list .faq_wrapper h3');
		faqItems.forEach(faqItem => {
			faqItem.addEventListener('click', function(e){
				let clickedItem = e.currentTarget;
				clickedItem.parentNode.toggleAttribute('open');
			});
		});
        
		setTimeout(() => {
			faqItems[0].click();
		}, "500");
	}

	my.init = function(){
		_faqToggle();
	};

	return my;
})();